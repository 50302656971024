import React from "react";
import LoadingGif from '../img/loading.gif'

const Loading = (props) => {
    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', height: '400px', backgroundColor: 'white', margin: 'auto', ...props.style}}> 
            <img src={LoadingGif} style={{width: '64px', height: '64px'}} alt="loading"/>
        </div>
    )
}

export default Loading;