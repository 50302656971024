import Cookies from 'universal-cookie'
import ConnectionFinder from '../api/ConnectionFinder';
const VisitCookie = () => {
    const cookies = new Cookies();
    if (cookies.get('cookiesAllowed') !== true) return;
    if (cookies.get('Visited') === true) return;
    const date = new Date();
    date.setDate(date.getMinutes() + 1);
    cookies.set('Visited',true,{expires: new Date(Date.now() + 1000*60*60*24), sameSite: 'strict'});
    ConnectionFinder.post('/require/visit').then((response) => {
        console.log(response.data)
    })
    
    
}
export default VisitCookie;