import React from "react";
import Navbar from "../Navbar";
import '../styles/otherPages.css'

import Logo from '../../img/2t.png'

const Payments = () => {

    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
        <Navbar />
        <div style={{backgroundColor: 'rgba(255, 15, 100, 0.5)', minHeight: 'calc(100vh - 302px)'}}>
            <div style={{backgroundColor: '#ECF0F1', height: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h1 className="discTitle aOTitle" style={{color: 'rgba(255, 15, 100, 0.7)'}}>#connectyou.</h1>            
            </div>
            <div style={{height: '5px'}}></div>
            <div style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%', textAlign: 'center'}}>
                <div style={{width: '97%', height: '300px'}}>
                    <h1 className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '4rem', fontWeight: '700'}}>Pagamenti PayPal</h1>
                    <br />
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Pagamento fatture <span style={{color: '#ff0f64', fontWeight: '700'}}>TLC</span>.</p>
                    <br />
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="UECV4KQHUF85S" />
                        <input type="hidden" name="currency_code" value="EUR" />
                        <input type="image" src="https://www.paypalobjects.com/it_IT/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal, il metodo semplice e sicuro per pagare online" alt="Acquista ora" width={140}/>
                    </form>
                </div>
            </div>
        </div>
        <div style={{margin: 'auto', backgroundColor: '#ECF0F1', display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '5px'}}>
                <img src={Logo} alt="Logo" style={{margin: 'auto', height: '120px'}}/>
        </div>
    </div>
    )

}

export default Payments;