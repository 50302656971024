import React from "react";
import Navbar from "../Navbar";
import Logo from '../../img/2t.png'
import FTTC from '../../img/fttc-map.png';
import FTTH from '../../img/ftth-map.png';
import FD from '../../img/fd-map.png';
import '../styles/Technologies.css'
import themePic from '../../img/5.png'


const TechDescription = () => {
    return (
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            {/* <div style={{backgroundColor: '#ECF0F1', height: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center',marginTop: '10px', marginBottom: '10px'}}>
                    <h1 className="discTitle aOTitle" style={{color: 'rgba(255, 15, 100, 0.7)'}}>#connectyou.</h1>            
            </div> */}
            <div className="textTitle" style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%'}}>
                    <div className="titleSection">
                        <h1 >Descrizione tecnologie</h1>
                        <br />
                        <p style={{paddingBottom: '220px'}}>Leggiamo sempre queste sigle: FTTC, FTTH ma facciamo un attimo di chiarezza.</p>
                        
                    </div>
                    <div className="textContainer">
                        <img src={themePic} alt=""  style={{position: 'relative', height: 'auto', marginTop: '-200px'}}/>
                        <br />
                        
                        <h2 id="FTTC" className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '3rem', fontWeight: '700'}}>FTTC</h2>
                        <p>La copertura <span style={{color: '#ff0f64', fontWeight: '700'}}>FTTC</span> fa riferimento alla fibra misto rame in tecnologia VDSL e EVDSL che TIM mette a disposizione agli operatori.</p>
                        <div style={{width: '100%', height: 'auto', marginTop: '10px', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                <img src={FTTC} alt="" style={{width: '100%'}}/>
                        </div>
                        
                        
                        <h2 id="FTTH" className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '3rem', fontWeight: '700'}}>FTTH</h2>
                        <p>La copertura <span style={{color: '#ff0f64', fontWeight: '700'}}>FTTH</span> fa riferimento alla rete interamente in fibra di Fibercop.</p>
                        <p>La sigla FTTH indica infine tutti quei collegamenti in cui la Fibra Ottica copre entrambe le tratte, partendo dalla centrale ed arrivando fino all’interno della casa dell’utente. Con questa tecnologia è possibile arrivare a prestazioni fino a 10Gbps.</p>
                        <div style={{width: '100%', height: 'auto', marginTop: '10px', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                <img src={FTTH} alt="" style={{width: '100%'}}/>
                        </div>
                        
                        {/* <a id="dedicata">*/}
                        <h2 id="dedicata" className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '3rem', fontWeight: '700'}}>FIBRA DEDICATA</h2>
                        <p>La Fibra Ottica dedicata a progetto è fibra FTTH spillata direttamente dalla dorsale e portata in esclusiva direttamente 
                        in sede cliente con scavo stradale dedicato e permessi pubblici a carico di <span style={{color: '#ff0f64', fontWeight: '700'}}>TLC Telecomunicazioni </span> anche dove la Fibra condivisa non è disponibile.</p>
                        <p>Nei circuiti in fibra ottica dedicata la banda garantita è pari alla banda di picco. Non esiste quindi alcuna condivisione del servizio ne a livello infrastrutturale ne a livello di banda.</p>
                        <div style={{width: '100%', height: 'auto', marginTop: '10px', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                                <img src={FD} alt="" style={{width: '100%'}}/>
                        </div>
                        <p> L’intera infrastruttura e l’intera banda richiesta sono totalmente a disposizione del singolo cliente richiedente. </p>

                    
                    </div>
                </div>
                <div style={{margin: 'auto', backgroundColor: '#ECF0F1', display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '5px', marginBottom: '6px'}}>
                        <img src={Logo} alt="Logo" style={{margin: 'auto', height: '120px'}}/>
                </div>
        </div>
    )
}

export default TechDescription;