import React,{useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './style.css'
import ConnectionFinder from '../api/ConnectionFinder';
import { useSelector, useDispatch } from 'react-redux';
import Simlaritysort from './SimilaritySort';
import { changeAddress, changeCity,changeNumber,changeErase, } from '../actions';
const FreeSolo2 = ({label, filled, classes,value}) => {
  let region = useSelector(state => state.params.region.trim()) 
  const dispatch = useDispatch();
  const eraseState = useSelector(state => state.params.erase)
  const [cities, setCities] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [EgonList, setEgonList] = useState([]);
  const [cityValue, setCityValue] = useState(value);
  const [addressValue, setAddressValue] = useState(value);
  const [numberValue, setNumberValue] = useState(value)
  useEffect(() => {
    const resetCity = () => {
      dispatch(changeCity(""))
      setCityValue("")
      setCities([])
    }
    const resetAddress = () => {
      setAddresses([])
      setAddressValue("")
      dispatch(changeAddress({egon:"", name: ""}));
    }
    const resetNumber = () => {
      setNumbers([])
      setNumberValue("")
      dispatch(changeNumber(""));
    }
    const checkErase = eraseState;
    dispatch(changeErase(0))
    if (checkErase === 0)
      return;
    if (checkErase === 3){
      resetCity();
      resetAddress();
      resetNumber();    
      return;
    }
    if (checkErase === 1) {
      resetAddress();
      resetNumber();        
      return;
    }
    resetNumber();
  },[eraseState,dispatch])

  let ricercaComune = (e) => {
    const value = e.target.value;
    const previousValue = document.getElementById('region').textContent;

    if(previousValue.length === 1) return alert("Inserisci prima la regione.");

    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }
    
    if(value.length !== 3) return;
    ConnectionFinder.post("/require/name", {
      name: value,
      region
    }).then((response) => {
      response.data = Simlaritysort(response.data,value,"Denominazione_in_italiano");
      setCities(response.data)
    })

  }

  let ricercaIndirizzo = (e) => {
    const value = e.target.value;
    const previousValue = document.getElementById('cname').value;

    if(previousValue.trim().length === 0) return alert("Inserisci prima il comune.");

    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }
    else if(value.length === 3) {      
      ConnectionFinder.post("/require/address", {
        region,
        name: value,
        city: previousValue,
      }).then((response) => {
        setEgonList(response.data)
        const list = Simlaritysort(response.data,value,"address")
        setAddresses(list)
        setCities([])
      })
    }
    

  }
  let previousValue = useSelector(state => state.params.address.egon);
  let ricercaCivico = (e) => {
    const value = e.target.value;

    if(previousValue.trim().length === 0) return alert("Inserisci prima l'indirizzo.");
    
    if(value.length === 0) {
      setCities([])
      setAddresses([])
      setNumbers([])
    }

    if(value.length !== 1) return;

    const city = document.getElementById('cname').value
    
    ConnectionFinder.post("/require/number", {
      region,
      name: value,
      address: previousValue,
      city
    }).then((response) => {
      setNumbers(response.data)
      setAddresses([])
      setCities([])
    })

  }
  
  const EgonSelected = (e,value) =>{
    if (addressValue === ""){
      dispatch(changeAddress({egon:"", name: ""}));
      return;
    }
    const index = EgonList.map((e) =>{return e.address}).indexOf(value)
    dispatch(changeAddress({egon:EgonList[index].egon, name: value}));
    dispatch(changeErase(2))
  }
  
  var sus;

  if(label === "Comune") {
    
    sus = (<div style={{ width: "90%" }}>
    <Autocomplete
      classes={classes}
      id="cname"
      noOptionsText = "Nessun risultato"
      openText='Apri'
      value={cityValue}
      onInputChange={(e,value)=>{setCityValue(value)}}
      closeText=''
      disableClearable
      onChange={()=>dispatch(changeErase(1))}
      onClose={(e) => {
        if (e.target.textContent === "" && e._reactName !== "onKeyDown")
          dispatch(changeErase(3))
      }}
      isOptionEqualToValue={()=>{return true}}
      options={cities.map((option) => option.Denominazione_in_italiano.toUpperCase())}
      renderInput={(params) => (
      <TextField {...params} label={label} value={cityValue} hiddenLabel aria-label="Comune" margin="normal" onChange={(e) => ricercaComune(e)}  variant="outlined" required />
      )}
    />
  </div>);
  }
  if(label === "Indirizzo") {
    sus = (<div style={{ width: "90%" }}>
    <Autocomplete
      disablePortal
      value={addressValue}
      id="address"
      noOptionsText = "Nessun risultato"
      onChange={(e,value)=>{
        EgonSelected(e,value)
      }}
      openText='Apri'
      closeText=''
      isOptionEqualToValue={()=>{return true}}
      onClose={(e) => {
        if (e.target.textContent === "" && e._reactName !== "onKeyDown")
          dispatch(changeErase(1))
      }}
      style={{borderRadius: '0px'}}
      onInputChange={(e,value)=>{setAddressValue(value)}}
      disableClearable
      options={addresses.map((option) => option.address)}
      renderInput={(params) => (
      <TextField {...params} id='addressvalue' label={label} style={{borderRadius: '0px'}} margin="normal" onChange={(e) => ricercaIndirizzo(e)} variant="outlined" required/>
      )}
      classes={classes}
    />
  </div>);
  }

  

  if(label === "Civico") {
    sus = (<div style={{ width: "90%" }}>
    <Autocomplete
      classes={classes}
      id="number"
      noOptionsText = "Nessun risultato"
      openText='Apri'
      isOptionEqualToValue={()=>{return true}}
      closeText=''
      disableClearable
      onClose={(e) => {
        if (e.target.textContent === "" && e._reactName !== "onKeyDown")
          dispatch(changeErase(2))
      }}
      value={numberValue}
      onInputChange={(e,value)=>{setNumberValue(value)}}
      options={numbers.map((option) => option.civic)}
      renderInput={(params) => (
      <TextField {...params} label={label} margin="normal" onChange={(e) => ricercaCivico(e)}  variant="outlined" required/>
      )}
    />
  </div>);
  }


  return sus;
}

export default FreeSolo2;