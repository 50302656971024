import React,{useState, useEffect} from 'react';
import {GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import Loading from './Loading';

const libraries = ['places'];

const Maps = (props) => {

    const mapContainerStyle = {
        width: "100%",
        height: "500px",
        // border: '1px solid #ff0f64',
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    }

    //const [zoom, setZoom] = useState(15);

    let zoom = 15;

    const [center, setCenter] = useState({lat: 0, lng: 0});
    const [selectedMarker, setSelectedMarker] = useState("")

    let pattern = new RegExp('([0-9.]+)', 'g');

    let x = props.locations[0].COORDINATE_BUILDING.match(pattern);

    useEffect(() => {
        try{setCenter({lat: parseFloat(x[0]), lng: parseFloat(x[1])})}
        catch(e){
            console.log(e)
            setCenter({lat: 12, lng: 42})
        }
        // eslint-disable-next-line
    },[])


    if(props.locations.length === 1000) zoom = 8

    const {isLoaded, loadError} = useLoadScript(
        {
            googleMapsApiKey: process.env.REACT_APP_API_KEY,
            libraries
        }
    )       

    if(loadError) return "Error loading maps!";
    if(!isLoaded) return <Loading />

    return(
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div style={{ width: '92%', marginTop: '10px', marginBottom: '30px'}}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={zoom}
                    options={{streetViewControl: false}}
                    center={center}>
                    {
                        props.locations.filter((m) => {
                            if(m.COORDINATE_BUILDING.match(pattern) === null ) return false;
                            return true; 
                        }).map((marker) => {
                            let coords =  marker.COORDINATE_BUILDING.match(pattern); 
                            return(
                                <Marker 
                                    key={marker.COORDINATE_BUILDING+Math.random()}
                                    position={{lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}}
                                    onClick={() => {
                                        setSelectedMarker({lat: parseFloat(coords[0]), lng: parseFloat(coords[1]), address: `${marker.PARTICELLA_TOP} ${marker.INDIRIZZO} ${marker.CIVICO}`, stair: marker.SCALA_PALAZZINA})
                                    }}
                                />
                            )
                        })
                    }
                    {selectedMarker && <InfoWindow onCloseClick={(() => setSelectedMarker(""))} position={selectedMarker}>
                        <div>
                            {selectedMarker.address}
                            <br/> 
                            {selectedMarker.stair !== "" && <p><b>SCALA: </b> {selectedMarker.stair}</p>}
                        
                        </div>
                    </InfoWindow>}                    
                </GoogleMap>
                <p style={{color: '#5f6a6a', marginTop: '4px'}}>*Le coordinate fornite non sono sempre esatte</p>
            </div>
        </div>       
    )
}

export default Maps;