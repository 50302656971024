import React from "react";
import Navbar from "../Navbar";
import '../styles/otherPages.css'

import Logo from '../../img/2t.png'

const AboutUs = () => {
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: 'rgba(255, 15, 100, 0.5)', minHeight: 'calc(100vh - 302px)'}}>
                <div style={{backgroundColor: '#ECF0F1', height: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 className="discTitle aOTitle" style={{color: 'rgba(255, 15, 100, 0.7)'}}>#connectyou.</h1>            
                </div>
                <div style={{height: '5px'}}></div>
                <div style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%'}}>
                    <div className="textContent">
                        <h1 className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '4rem', fontWeight: '700'}}>Chi siamo</h1>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{color: '#ff0f64', fontWeight: '700'}}>Fiberfinder</span> è il cercatore di Fibra del gruppo TLC Telecomunicazioni.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Al momento è in grado di verificare l’eventuale copertura in fibra ottica <span style={{color: '#ff0f64', fontWeight: '500'}}>FTTH</span> su tutta la rete <span style={{color: '#ff0f64', fontWeight: '500'}}>Fibercop</span> e <span style={{color: '#ff0f64', fontWeight: '500'}}>Open Fiber</span>.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{color: '#ff0f64', fontWeight: '500'}}>TLC Telecomunicazioni</span> è una compagnia telefonica nazionale attiva da circa 20 anni e specializzata nell’erogazione di servizi in fibra ottica dedicata a progetto per il top business, servizi dati in fibra di alta gamma per privati ed aziende, servizi di backup e ip continuity, servizi voce VOIP.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Dal 2021 si è aperta anche la mercato <span style={{color: '#ff0f64', fontWeight: '500'}}>wholesale</span> mettendo la sua esperienza ed una gamma completa di prodotti e servizi chiavi in mano a disposizione anche per piccolo ISP, WISP e Resellers.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Info: <a href="https://www.tlctel.com" style={{color: '#ff0f64'}}>www.tlctel.com</a> <a href="https://www.rassegnastampatlc.it" style={{color: '#ff0f64'}}>www.rassegnastampatlc.it</a></p>
                    </div>
                </div>
            </div>
            <div style={{margin: 'auto', backgroundColor: '#ECF0F1', display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '5px'}}>
                    <img src={Logo} alt="Logo" style={{margin: 'auto', height: '120px'}}/>
            </div>
        </div>
    )
}

export default AboutUs;