export const CAMBIACIVICO = "CAMBIACIVICO";
export const CAMBIACITTA = "CAMBIACITTA";
export const CAMBIAINDIRIZZO = "CAMBIAINDIRIZZO";
export const CAMBIAREGIONE = "CAMBIAREGIONE";
export const CANCELLA = "CANCELLA";

export const changeCity = (city) => {
    return{
        type: CAMBIACITTA,
        city,
    }
}

export const changeAddress = (address) => {
    return{
        type: CAMBIAINDIRIZZO,
        address,
    }
}

export const changeNumber = (civic) => { 
    return{ 
        type: CAMBIACIVICO, 
        civic, 
    } 
}

export const changeRegion = (region) => { 
    return{ 
        type: CAMBIAREGIONE, 
        region, 
    } 
}

export const changeErase = (erase)=>{
    return{
        type: CANCELLA,
        erase,
    }
}