import React from "react";
import Navbar from "../Navbar";
import '../styles/otherPages.css'

import Logo from '../../img/2t.png'

const PrivateArea = () => {
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: 'rgba(255, 15, 100, 0.5)', minHeight: 'calc(100vh - 302px)'}}>
                <div style={{backgroundColor: '#ECF0F1', height: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <a href="https://private.fiberfinder.it"><h1 className="link" style={{color: 'rgba(255, 15, 100, 0.7)', textDecoration: 'underline'}}>private.fiberfinder.it</h1></a>         
                </div>
                <div style={{height: '5px'}}></div>
                <div style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%'}}>
                    <div className="textContent">
                        <h1 className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '4rem', fontWeight: '700'}}>Area riservata</h1>
                        <br />
                        <ul>
                            <li className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>L'area riservata di <span style={{fontWeight: '700', color: '#ff0f64'}}>fiberfinder.it</span> consente le seguenti azioni (salvo altre in implementazione): </li>
                            <li className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>● Ricerca ed estrazione dei risultati su file per INTERO COMUNE e per INTERA VIA, oltre che per civico.</li>
                            <li className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>● Per la rete OPEN FIBER è possibile filtrare i risultati per cluster. (AB, CD, Italia 1 Giga)</li>
                            <li className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>● Per la rete FIBERCOP è possibile filtrare i risultati tra ATTIVI e PIANIFICATI. È inoltre possibile distinguere tra coperture FTTC ed FTTH e solo FTTH.</li>
                            <li className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>● Per ogni tipologia di estrazione si potranno scegliere i campi da visualizzare e procedere all'estrazione dei risultati in CSV. (È in sperimentazione il download in Excel)</li>
                        </ul>
                        <br />
                        <h2 style={{color: '#ff0f64', marginLeft:'20px'}}>Note:</h2>
                        
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Siamo in versione beta 1 sono possibili errori. Segnalarli eventualmente a commerciale@tlctel.com <br /> In caso di ricerche con risultati molto numerosi gli stessi sono divisi a blocchi di 1000 indirizzi</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{fontWeight: '700', color: '#ff0f64'}}>Attenzione!</span> L'area riservata di fiberfinder è utilizzabile esclusivamente al fine di effettuare una verifica di copertura finalizzata alla vendita del servizio al cliente finale. Ci riserviamo di bloccarne l'utilizzo in caso di uso improprio a nostro insindacabile giudizio.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Per richiedere l'accesso all'area riservata di fiberfinder scrivere alla mail: commerciale@tlctel.com <br />
                            Fiberfinder è stato sviluppato da TLC Telecomunicazioni. I costi di accesso all'area riservata saranno quindi diversi a seconda che a chiedere l'accesso sia un nostro partner retail o wholesale o un soggetto non nostro partner.
                        </p>
                        <br />
                        <a href="https://private.fiberfinder.it" style={{margin: 'auto'}}><button className="submit" style={{width: 'fit-content',height: '80px', fontSize: '2rem', fontWeight: 'bold', padding: '0px 30px 0 30px'}}>Accedi</button></a>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>
                            Per i nostri partner retail l'abbonamento con accesso illimitato all'area riservata di fiberfinder ha un costo di 10 euro mensili o 100 euro con pagamento annuale anticipato oltre iva.
                            <br />
                            Per i nostri partner wholesale l'abbonamento con accesso illimitato all'area riservata di fiberfinder ha un costo di 20 euro mensile o 200 euro con pagamento annuale anticipato oltre iva. (il partner wholesale potrà richiedere di abilitare all'accesso anche i propri partner diretti con un costo ulteriore di 5 euro mensile, o 50 euro annuali anticipati oltre iva per ciascun codice di accesso aggiuntivo da abilitare)
                            <br/>
                            Per soggetti NON NOSTRI PARTNER l'abbonamento ha un costo 50 euro mensili o 500 euro con pagamento annuale anticipato.
                            <br />
                            Per essere considerati partner TLC non basta l'aver attivo un codice partner o aver sottoscritto un contratto wholesale. Consideriamo partner attivi solo i partner con almeno 10 contratti in portafoglio e che abbiano prodotto nei 12 mesi precedenti alla richiesta almeno 5 contratti.
                            <br />
                        </p>
                    </div>
                </div>
            </div>
            <div style={{margin: 'auto', backgroundColor: '#ECF0F1', display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '5px'}}>
                    <img src={Logo} alt="Logo" style={{margin: 'auto', height: '120px'}}/>
            </div>
        </div>
    )
}

export default PrivateArea;