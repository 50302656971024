import { useEffect, useState } from 'react';
import {HashLink as Link} from 'react-router-hash-link';

import active from '../img/active.png';
import inactive from '../img/inactive.png';
import warning from '../img/warning.png';


const TableSection = (props) => {

    const data = props.data;
    const type = props.type;
    const name = props.name;

    const [show, setShow] = useState(false)
    const [section, setSection] = useState();

    const setIcon = (state) => {
        if(state === "NO") return inactive;
        else if(state === "Attivo") return active;
        else return warning;
    }

    let upSpeed, downSpeed, distance, state;

    upSpeed = data["VELOCITA_UP"+name];
    downSpeed = data["VELOCITA_DOWN"+name]
    distance = data["DISTANZA_BITSTREAM_NGA_VULA_FTTCab"]
    state = data["COPERTURA"+name]

    let status;
    if(data["COPERTURA"+name] === 'Attivo' || data["COPERTURA"+name] === 'SI') status = '#2ECC71'
    else if(data["COPERTURA"+name] === 'NO') status = '#D2042D'
    else status = '#F1C40F'

    const openSection = () => {
        setSection(
            <div className='tableSectionOpen' style={{display: 'block'}}>
                <div style={{height: '1px', backgroundColor : '#ff0f64', marginTop: '5px'}}></div>
                    <table className='dataTable' style={{margin: 'auto', width: '100%', marginBottom: '10px'}}>
                        <tbody>
                            {
                                props.tag === 'R' && [
                                    <tr><th colSpan={2}>Generali</th></tr>,
                                    <tr><td style={{fontWeight: '500', color: '#5f6a6a'}}>Distanza armadio</td><td>{distance}{(distance !== "N.D.") ? "m" : ""}</td></tr>,
                                    <tr><th colSpan={2}>Velocità massime stimate</th></tr>,
                                    <tr><td  style={{fontWeight: '500', color: '#5f6a6a'}}>Download</td><td>{downSpeed+" Mb/s"}</td></tr>,
                                    <tr><td  style={{fontWeight: '500', color: '#5f6a6a'}}>Upload</td><td>{upSpeed+" Mb/s"}</td></tr>,

                                ]
                            }
                            {
                                props.type === 'FTTH' && [
                                    <tr><th colSpan={2}>Generali</th></tr>,
                                    <tr><td style={{fontWeight: '500', color: '#5f6a6a'}}>1Gbit/s</td><td><img src={setIcon(state)} alt="" style={{width: '24px', height: '24px', borderRadius: '4px' }}/></td></tr>,
                                    <tr><td style={{fontWeight: '500', color: '#5f6a6a'}}>2.5Gbit/s</td><td><img src={setIcon(state)} alt="" style={{width: '24px', height: '24px', borderRadius: '4px' }}/></td></tr>,
                                    <tr><td style={{fontWeight: '500', color: '#5f6a6a'}}>10Gbit/s</td><td><img src={setIcon(data["COPERTURA_BITSTREAM_NGA_VULA_XGSPON"])} alt="" style={{width: '24px', height: '24px', borderRadius: '4px' }}/></td></tr>
                                ]
                            }
                            {
                                props.type === 'FIBRA DEDICATA' && [
                                    <br />,
                                    <tr><td style={{fontWeight: '500', color: '#5f6a6a', width: '50%', height: '35px'}}>Fascia</td><td><span style={{backgroundColor: '#ff0f64', color: 'white', fontWeight: "500", padding: '3px', paddingLeft: '6px', paddingRight: '6px', borderRadius: '5px'}}>{data["FASCIA_CIRCUITI_TERM_ETH_FIBRA_OTTICA"]}</span></td></tr>,
                                    <tr><td  style={{border: '0', textAlign: 'left'}} colSpan={2}>
                                        <p style={{marginBottom: "8px"}}>La fibra ottica dedicata offre una connessione Internet ad altissima velocità, affidabilità e stabilità.</p>
                                        <p>La soluzione è riservata alla clientela Top Business.</p>
                                        <br />
                                        <Link to="/technologies#dedicata">Ulteriori informazioni.</Link>
                                    </td></tr>
                                ]
                            }
                        </tbody>
                    </table>
                <div style={{height: '1px', backgroundColor : '#dee2e6', marginTop: '5px'}}></div>
            </div>
        )
    }
    
    useEffect(() => {
        show ? setTimeout(() => openSection(), 100) : setSection(null)
        // eslint-disable-next-line
    }, [show])

    return(
        <div style={{height: show ? '290px' : '50px', maxHeight: '290px'}} className='tableSection'>
            <div style={{display: 'grid', gridTemplateColumns: '33% 33% 33%', alignItems: 'center', margin: 'auto', textAlign: 'center'}}>
                <h2 className="tableSectionTitle">{type}</h2>
                <p style={{fontWeight: 'bold', color: 'white', backgroundColor: status, width: '120px', margin: 'auto', borderRadius: '4px'}}>{state.toUpperCase()}</p>
                {(data["COPERTURA"+name] !== "NO") && <div> <p style={{textDecoration: 'underline', cursor: 'pointer', fontWeight: '500', userSelect: 'none',width: 'fit-content', margin: 'auto'}} onClick={() => { setShow(!show)}} >{show ? 'Chiudi' : 'Dettagli'}</p> </div>}
            </div>
            {section}
        </div>
    )

}

export default TableSection;