import React from 'react';
import './styles/Footer.css';
import Logo from '../img/logoFiberFinder.svg';


const Footer = () => {
    return(
        <div className='fContainer'>
            <img src={Logo} alt="logo" className='fLogo' />
            <div className='infos'>
                <h2 className='gT'>Gruppo TLC Telecomunicazioni</h2>
                <p>Fiberfinder è un marchio di TLC Telecomunicazioni srl. Tutti i diritti riservati.</p>
                <p>Fiberfinder è uno degli strumenti di verifica copertura FIBRA FTTH in Italia più affidabile e completo. Al momento è in grado di effettuare ricerche su tutta la rete Fibercop e Open Fiber.</p>
                <p style={{color: 'white'}}><a href="https://fiberfinder.it/disclaimer" style={{color: 'inherit',textDecoration: 'underline'}}>DISCLAIMER</a> - <a href="https://fiberfinder.it/opservices" style={{color: 'inherit',textDecoration: 'underline'}}>SERVIZI PER OPERATORI DI TELECOMUNICAZIONI / SYSTEM INTEGRATOR / AGENTI / AGENZIE</a></p>
            </div>
        </div>
    )
}

export default Footer;