import React from "react";
import Navbar from "../Navbar";
import '../styles/otherPages.css'

import Logo from '../../img/2t.png'
const ApiPage = () =>{
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: 'rgba(255, 15, 100, 0.5)', minHeight: 'calc(100vh - 302px)'}}>
                <div style={{backgroundColor: '#ECF0F1', height: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 className="discTitle aOTitle" style={{color: 'rgba(255, 15, 100, 0.7)'}}>FiberFinderAPI</h1>            
                </div>
                <div style={{height: '5px'}}></div>
                <div style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%'}}>
                    <div className="textContent">
                        <h1 className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '4rem', fontWeight: '700'}}>API Fiberfinder</h1>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Visita <span style={{fontWeight: '700'}}><a href="https://rapidapi.com/TlcTel/api/fiberfinderapi">Questa pagina</a></span> per implementare le<span style={{color: '#ff0f64', fontWeight: '700'}}> API di fiberfinder</span> sul tuo gestionale o sul tuo sito web.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Le prime 40 richieste al giorno sono gratuite, mentre per le successive 0,01 € a richiesta. Per ottenere un risultato valido sono necessarie 4 richieste da ciò, con le prime 40 richieste gratuite sarà possibile ottenere fino a 10 risultati validi, successivamente, visto che per generare un risultato valido occorrono 4 richieste ogni verifica costerà 0.04 euro.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{fontWeight: '700', color: '#ff0f64'}}>Attenzione!</span> Cambi di civico e/o indirizzo generano anch’essi una richiesta.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Le api sono ancora in versione <span style={{fontWeight: '700', color: '#ff0f64'}}>beta</span> e potrebbero presentarsi errori; nel caso ciò accadesse scrivere alla mail:  <span style={{fontWeight: '700', color: '#ff0f64'}}>commerciale@tlctel.com</span></p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{fontWeight: '700', color: '#ff0f64'}}>Importante!</span> Le API di fiberfinder sono utilizzabili esclusivamente al fine di effettuare una verifica di copertura tesa alla vendita del servizio al cliente finale. Ci riserviamo di bloccarne l’utilizzo in caso di usi impropri a nostro insindacabile giudizio.</p>
                        <br />
                        <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{fontWeight: '700', color: '#ff0f64'}}>IN ARRIVO</span>: componente <span style={{fontWeight: '700', color: '#ff0f64'}}>wordpress</span> Fiberfinder</p>
                    </div>
                </div>
            </div>
            <div style={{margin: 'auto', backgroundColor: '#ECF0F1', display: 'flex', justifyContent: 'center', padding: '20px', marginTop: '5px'}}>
                    <img src={Logo} alt="Logo" style={{margin: 'auto', height: '120px'}}/>
            </div>
        </div>
    )
}
export default ApiPage;