import { combineReducers } from "redux";
import DataReducer from "./params"; 
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['params']
}

const rootReducers = combineReducers({
    params: DataReducer
})

export default persistReducer(persistConfig, rootReducers);