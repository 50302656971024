import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { changeRegion,changeErase } from '../actions';

export default function SelectResults(props) {

  const dispatch = useDispatch();

  const items = props.items;
  const value = props.value;

  return (
    <Box sx={{ width: '90%',
    backgroundColor: 'white',
        borderRadius: '10px',
      '& .MuiInputBase-root': {
        borderRadius: '10px',
        height: '40px',
      },
      "&.MuiSelect-select":{
        
      },
    }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{
          '&.MuiFormLabel-root': {
            marginLeft: '20px',
            '&.Mui-focused': {
              color: '#ff0f64',
              backgroundColor: 'white',
              marginLeft: 0,
            },
            "&.MuiInputLabel-shrink": {
              marginLeft: 0
            }
          }
        }}>{props.label}</InputLabel>
        <Select
            defaultValue={''}

            labelId="region-label"
            id="region"
            value={value}
            sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '8.5px',
                  paddingRight: '6px !important'
                },
                '& .MuiOutlinedInput-notchedOutline ': {
                  borderWidth: '2px',
                  borderColor: '#ff0f64',
                },
                
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'gray'
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                  borderWidth: "1px"
                },

            }}
            onChange={(e) => {
              dispatch(changeRegion(e.target.value));
              dispatch(changeErase(3))
            }}
        >
          {items.map((item) => (
            <MenuItem value={item} key={Math.random()}>{item.toUpperCase()}</MenuItem>            
          ))}
          
        </Select>
      </FormControl>
    </Box>
  );
}

