import { CAMBIAINDIRIZZO } from "../actions";
import { CAMBIACITTA } from "../actions";
import { CAMBIACIVICO } from "../actions";
import { CAMBIAREGIONE } from "../actions";
import { CANCELLA } from "../actions";
const initialState = {
    region: "",
    city: "",
    address: "",
    number: "",
}
 
 
 
const DataReducer = (state = initialState, action) => {
    switch(action.type) {
        case CAMBIAINDIRIZZO: //crea e importa il nome dell'action
            const newAddress = action.address //ciò che passerai a dispatch dell'azione
            return { //ritroniamo una copia dello stat3e e diciamo che address è uguale a new address
                ...state,
                address: newAddress
            };
        case CAMBIACITTA: //crea e importa il nome dell'action
            const newCity = action.city //ciò che passerai a dispatch dell'azione
            return { //ritroniamo una copia dello stat3e e diciamo che city è uguale a newCity
                ...state,
                city: newCity
            };
        case CAMBIACIVICO: //crea e importa il nome dell'action
            const newCivic = action.civic //ciò che passerai a dispatch dell'azione
            return { //ritroniamo una copia dello stat3e e diciamo che city è uguale a newCity
                ...state,
                civic: newCivic
            };
        case CAMBIAREGIONE:
            const newRegion = action.region
            return {
                ...state,
                region: newRegion
            };
        
        case CANCELLA:
            const newErase = action.erase;
            return {
                ...state,
                erase: newErase
            };
        default:
            return state
    }
}

export default DataReducer;