import React from "react";
import '../styles/otherPages.css'
import Navbar from "../Navbar";

import Operator from '../../img/operator.svg';


const OperetorServices = () => {
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)', backgroundColor: 'white'}}>
            <Navbar />
            <div className="textContent" style={{backgroundColor: 'white', minHeight: 'calc(100vh - 302px)'}}>
                <img src={Operator} alt="" style={{ width: '180px', marginLeft: '10px'}}/>
                <h1 className="discTitle op">Servizi per gli operatori</h1>
                <div style={{width: '98%'}}>
                    <br />
                    <p style={{fontSize: '28px', marginLeft: '20px'}}>Sei un operatore? Una agenzia? Un agente di qualsiasi compagnia telefonica?</p>
                    <br />
                    <p style={{fontSize: '28px', marginLeft: '20px'}}>Abbonandoti a Fiberfinder.it PRIVATO potrai estrarre su base via o su base comune, le liste complete degli indirizzi coperti dalla fibra ottica.</p>
                    <br />
                    <p style={{fontSize: '30px', marginLeft: '20px'}}>Per informazioni: <a href="mailto:commerciale@tlctel.com" style={{color: '#ff0f64'}}>commerciale@tlctel.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default OperetorServices;