
const Simlaritysort = (props,value, key) =>{
    //funzion compare per sortare prima per priorità e poi per la distanza
    function cmp(a,b){
        if (a.priority === b.priority)
            return a.distance - b.distance;
        if (a.priority)
            return -1;
        return 1;
    }
    
    function levenshteinDistance(a, b) {
        let distances = new Array(a.length + 1);
        for (let i = 0; i <= a.length; i++) {
            distances[i] = new Array(b.length + 1);
        }
        for (let i = 0; i <= a.length; i++) {
            distances[i][0] = i;
        }
        for (let j = 0; j <= b.length; j++) {
            distances[0][j] = j;
        }
        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                if (a[i - 1] === b[j - 1]) {
                    distances[i][j] = distances[i - 1][j - 1];
                } else {
                    distances[i][j] = Math.min(distances[i - 1][j], distances[i][j - 1], distances[i - 1][j - 1]) + 1;
                }
            }
        }
        return distances[a.length][b.length];
    }
    
    function sortBySimilarity(words, singleWord) {
        for (let i = 0; i < words.length; i++){
            words[i].distance = levenshteinDistance(words[i][key], singleWord)
            words[i].priority = words[i][key].substr(0,singleWord.length).toLowerCase() === singleWord.toLowerCase()
        }
        words.sort(cmp);
        return words;
    }
    props = sortBySimilarity(props, value);
    return (props);
}
export default Simlaritysort;