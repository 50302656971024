import React, {useEffect, useState} from "react";
import './styles/Cookies.css'
import close from '../img/close2.png'
import Cookies from 'universal-cookie'
import Pdf from './Documents/Privacy-Internet-e-cokies.pdf';
import VisitCookie from "./VisitCookie";

const Cookie = () => {

    const cookies = new Cookies();

    const [display, setDisplay] = useState(true)

    const date = new Date();
    date.setFullYear(date.getFullYear() + 1000)

    const c = cookies;

    useEffect(() => {
        if(c.get('cookiesAllowed') === true || c.get('cookiesAllowed') === 'notAllowed') setDisplay(false)
    },[setDisplay, c])


    const allowCookie = () => {
        cookies.set('cookiesAllowed', true,{expires: date, sameSite: 'strict'})
        VisitCookie();
        setDisplay(false)
    }   

    const closeCookie = () => {
        cookies.set('cookiesAllowed', 'notAllowed',{expires: date, sameSite: 'strict'})
        setDisplay(false)
    }

    return(
        <>  
        {display && <div className="cContainer">
                <p  className="ct" >Questo sito utilizza solamente Cookies Tecnici e non di Profilazione. Sono innocui e in nessun caso permettono la tua identificazione. Cliccando su Accetto acconsenti all'utilizzo di tali Cookies.</p>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <button className="cButton" onClick={() => allowCookie()}>Accetta</button>
                    <a href={Pdf} target="_blank" rel="noopener noreferrer" style={{marginLeft: '30px'}}><button className="cButton" style={{marginLeft: '0'}}>Maggiori informazioni</button></a>
                </div>
                <img style={{width: '24px', height: '24px', margin: 'auto', marginRight: '10px'}} src={close} alt="close" onClick={() => closeCookie()}/>
            </div>
        }
        </>
    )
}



export default Cookie;