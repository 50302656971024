import React from "react";
import Logo from '../img/bot.svg'
import './styles/NotFound.css';
import {useNavigate} from 'react-router-dom';

const NotFound = () => {

    const navigate = useNavigate();

    return(
        <div style={{'width': '100vw', 'height': '100vh', 'display': 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', backgroundColor: '#ff0f64', overflow: 'hidden'}}>
            <div className="nfContainer"> 
                <img className="img" src={Logo} alt=""/>
                <div className="text" style={{ top: '28%'}}>
                    <h1 className="t404">404</h1>
                    <h1 style={{fontSize: '6vh'}}>Page not found</h1>
                    <p style={{fontSize: '3vh', fontWidth: 'bold', backgroundColor: '#ff0f64', color: 'white', width: '80%', marginLeft: '10%', borderRadius: '13px', padding: '3px', marginTop: '30px'}} onClick={() => navigate('/', {replace: true})}>Torna indietro</p>
                </div>
            </div>
        </div>


       
    )

}

export default NotFound;