import React from "react";
import { useNavigate } from "react-router";
import './styles/ContactUs.css';
import {HashLink as Link} from 'react-router-hash-link';
import Happy from '../img/happy.svg';
import Sad from '../img/sad.svg';
import Neutral from '../img/warning2.svg';
import Check from '../img/check.svg';
import { useSelector } from "react-redux";

const ContactUs = (props) => {

    const status = props.status;
    const code = props.code;
    const FC = props.FC;
    const OF = props.OF;
    const setFilled = props.f;

    const navigate = useNavigate();

    const autoFilledResearch = async () => {
        navigate('/', {replace: true})
        await setFilled()
            
    }

    let AddrInfo = useSelector(state => state.params.address)

    const setPhrase = (FC) => {
        if(FC.COPERTURA_BITSTREAM_NGA_VULA_FTTH === 'Attivo')
            return <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>connesso</span> in <span style={{color:'#FF0F64', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate("/technologies#FTTH")}>FTTH</span> non è fantastico?</h1>

        if(FC.COPERTURA_BITSTREAM_NGA_VULA_FTTCab === 'Attivo') 
            return <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>connesso</span> in <span style={{color:'#FF0F64', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate("/technologies#FTTC")}>FTTC</span> non è fantastico?</h1>
    
        return <h1 className="connectionPhrase">Puoi essere <span style={{color:'#2ECC71'}}>raggiunto</span> dalla <span style={{color:'#FF0F64', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate("/technologies#dedicata")}>Fibra Dedicata</span>.</h1>
    }
    

    return(
        <div style={{width: '95%', height: 'fit-content', backgroundColor: 'white', margin: 'auto', marginBottom: '50px', borderRadius: '13px', border: 'none', display: 'flex',justifyContent: 'center', flexDirection: 'column'}}>
            {/* city_connected  */}
            {status === 'city_connected' && <div>
            <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Copertura FTTC / FTTH Fibercop</p>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Happy} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>Connesso</span>, non è fantastico?</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '100%'}}>Assicurati che il tuo indirizzo sia coperto facendo una ricerca più <span style={{color: '#ff0f64', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => autoFilledResearch()}>specifica</span> indicando via e civico.</h2>
                </div>
                <div style={{margin: 'auto', marginBottom: '30px',textAlign: 'center', color: '#5f6a6a', width: '95%'}}>
                    <p style={{fontSize: '1.6rem', fontWeight: '500'}}>Oppure contattaci chiamando il <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> o scrivici una mail ad <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</p>
                </div>
            </div> }

            {/* city_not_connected  */}
            {status === 'city_not_connected' && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Sad} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Oh no... Il tuo comune <span style={{color:'#D2042D'}}>non</span> è <span style={{color:'#D2042D'}}>Connesso</span>.</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '95%', margin: 'auto'}}>Se vuoi ricevere informazioni riguardo le soluzioni disponibili per te, contattaci direttamente al <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> o scrivici una mail ad <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
                <div style={{margin: 'auto', marginBottom: '30px',textAlign: 'center', color: '#5f6a6a', width: '95%'}}>
                    <p style={{fontSize: '1.6rem', fontWeight: '500'}}>Vuoi sapere quando il tuo comune verrà raggiunto dalla banda ultralarga? <span style={{color: '#008f39', whiteSpace: 'nowrap'}}>CONTATTACI!</span>.</p>               
                    <br />
                    <p style={{fontSize: '1.6rem', fontWeight: '500'}}>Di seguito potrai trovare lo stato attuale del tuo comune.</p>
                </div>
            </div> }
            {/* street_connected */}
            {status === 'street_connected' && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Happy} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>Connesso</span>, non è fantastico?</h1>
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%'}}>ATTENZIONE potrebbe capitare che alcuni civici non siano coperti, specificalo in una nuova <span style={{color: '#ff0f64', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => autoFilledResearch()}>ricerca</span>.</h2>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            </div> }
            {/* civic_connected */}
            {status === 'civic_connected' && <div>
                <p style={{color: '#ff0f64', fontSize: '34px', marginLeft: '20px', fontWeight: '700'}} className="barlow">Copertura FTTC / FTTH Fibercop</p>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Happy} alt='face' className="face"/>
                    {setPhrase(FC)}
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            </div> }
            {/* civic_connected_of */}
                {status === 'civic_connected_of' && <div>
                
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Check} alt='face' className="face"/>
                    <h1 className="connectionPhrase"><span style={{color:'#FF0F64'}}>Connesso</span> alla rete OpenFiber.</h1>
                    <br />
                    {(AddrInfo.name === "Non definito") ? 
                        <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci telefonando al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                    :
                        <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                    }
                </div>
            </div> }
            {/* not_connected but OF connected  */}
            {(status === 'not_connected' || status === 'fc_not_aviable') && (OF === 'civic_connected') && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Sad} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sembrerebbe tu <span style={{color:'#D2042D'}}>non</span> sia <span style={{color:'#D2042D'}}>Connesso</span>.</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Il tuo civico potrebbe non essere censito,per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            </div> }
            {/* not_connected */}
            {(status === 'not_connected' || status === 'fc_not_aviable') && (OF !== 'civic_connected') && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Sad} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Oh no...<span style={{color:'#D2042D'}}>non</span> sei <span style={{color:'#D2042D'}}>Connesso</span>.</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            </div> }
            {/* something_missing */}
            {status === 'something_missing' && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Neutral} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sembrerebbe ci siano dei <span style={{color:'#F1C40F'}}>problemi</span>.</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Stato: <span style={{color: '#F1C40F', whiteSpace: 'nowrap'}}>{code.num}</span> - {code.text}.</h2>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Se vuoi ricevere ulteriori informazioni a riguardo contattaci direttamente al <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> o scrivici una mail ad <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                    </div>
            </div> }
            {status === 'pac/pal' && 
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Happy} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>Connesso</span>, non è fantastico?</h1>
                    <br />
                    <h2 className="detalis" style={{ color: '#5f6a6a', margin: 'auto'}}><span style={{color: '#ff0f64'}}>ATTENZIONE</span>: L’indirizzo risulta coperto ma, essendo un civico censito come “pubblica amministrazione” è identificato come <span style={{color: '#ff0f64'}}>PAC/PAL</span>, in questo indirizzo sono vendibili solo FTTH dedicate punto/punto.</h2>
                    <h2 className="detalis" style={{ color: '#5f6a6a', margin: 'auto',marginTop: '7px'}}>Se vuoi ricevere informazioni riguardo le soluzioni disponibili per te, contattaci direttamente al <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> o scrivici una mail ad <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            }
            {status === 'of_not_aviable' && <div>
                <div style={{margin: 'auto', marginTop: '30px', textAlign: 'center', paddingBottom: '30px'}}>
                    <img src={Happy} alt='face' className="face"/>
                    <h1 className="connectionPhrase">Sei <span style={{color:'#2ECC71'}}>Connesso</span>, non è fantastico?</h1>
                    <br />
                    <h2 style={{fontSize: '1.5rem', color: '#5f6a6a', width: '96%', margin: 'auto'}}>Per ricevere informazioni sulle soluzioni disponibili per te, puoi contattarci tramite il  <Link to="#form" smooth style={{color: '#ff0f64'}}>form</Link>, telefonare al numero <span style={{color: '#008f39', whiteSpace: 'nowrap'}} onClick={() => window.location = 'tel:+39800132824'}>800 13 28 24</span> oppure inviare una mail a <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location = 'mailto:assistenza@tlctel.com'}>assistenza@tlctel.com</span>.</h2>
                </div>
            </div> }
        </div>
    )
}

export default ContactUs;