import React from "react";
import './styles/GeneralData.css';
import TableSection from './TableSection';

const GeneralData = (props) => {

    let data = props.data;

    let maxUp = 0, maxDow = 0, distance, unit = "Mb/s", fiberMaxSpeed = '0';

    try{
        if(data['COPERTURA_BITSTREAM_NGA_VULA_XGSPON'] === 'Attivo'){
            fiberMaxSpeed = '10 Gbit'
        }
        else if(data["COPERTURA_BITSTREAM_NGA_VULA_FTTH"] === "Attivo") {
            maxDow = 1000
            maxUp = 200
            fiberMaxSpeed = '2.5 Gbit'
        }
        else{
            for (let [key, value] of Object.entries(data)) {
                if(key.search("DOWN") !== -1) {
                    if(data[key.replace("VELOCITA_DOWN","COPERTURA")] !== "NO" && (Number(data[key]) > maxDow)) {
                        maxDow = value;
                    }
                }
                if(key.search("UP") !== -1) {
                    if(data[key.replace("VELOCITA_UP","COPERTURA")] !== "NO" && (Number(data[key]) > maxUp)) {
                        maxUp = value;
                    }
                }
            }
        }

        
        maxUp = Math.floor(maxUp)
        maxDow = Math.floor(maxDow);
        if(data.DISTANZA_BITSTREAM_NGA_VULA_FTTCab !== "N.D.") distance = Math.floor(data.DISTANZA_BITSTREAM_NGA_VULA_FTTCab)
        else distance = "N.D."
        if(maxDow + maxUp === 0) {
            maxUp = "N.D." 
            maxDow = "N.D."
        }

    }catch(e){

        maxUp = "N.D."
        maxDow = "N.D"
        distance = "N.D."
    }

    return(
        <div style={{width: '95%', height: 'auto', backgroundColor: 'white', margin: 'auto', marginBottom: '30px', borderRadius: '13px'}}>
            {data["COPERTURA_BITSTREAM_NGA_VULA_FTTH"] !== "Attivo" && <div style={{display: 'grid', gridTemplateColumns: '33% auto 33%'}}>
                <div style={{textAlign: 'center',marginBottom: '40px'}} className='connectionData'>
                    <span style={{textAlign: 'center', color: '#5F6A6A'}} className="connectionDataNames">UPLOAD</span>
                    <p style={{color: '#ff0f64',}} >{maxUp}</p>
                    <div className="connectionDataUnit">{unit}</div>
                </div>
                <div style={{textAlign: 'center',marginBottom: '40px'}} className='connectionData'>
                    <span style={{textAlign: 'center', color: '#5F6A6A'}} className="connectionDataNames">DOWNLOAD</span>
                    <p style={{color: '#ff0f64'}} className="dSpeed">{maxDow}</p>
                    <div className="connectionDataUnit">{unit}</div>
                </div>
                <div style={{textAlign: 'center',marginBottom: '40px'}} className='connectionData'>
                    <span style={{textAlign: 'center', color: '#5F6A6A'}} className="connectionDataNames">DISTANZA</span>
                    <p style={{color: '#ff0f64'}} >{distance}</p>
                    <div className="connectionDataUnit">Metri</div>
                </div>
            </div>
            }
            {
                data["COPERTURA_BITSTREAM_NGA_VULA_FTTH"] === "Attivo" && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',height: '200px', flexDirection: 'column'}}>
                    <h1 className="connectionDataNames gigabit">Sei coperto dalla rete FiberCop fino a <span style={{padding: '6px', backgroundColor: '#ff0f64', color: 'white', borderRadius: '7px'}}>{fiberMaxSpeed}</span>, grandioso!</h1>
                    <p style={{marginTop: 'auto', marginBottom: '5px'}} >Per ulteriori dettagli consulta la seguente sezione.</p>
                </div>
            }
            <div style={{border: '2px solid #ff0f64', width: '95%', margin: 'auto', borderRadius: '13px', marginBottom: '5px'}}>
                <h2 style={{textAlign: 'center', fontSize: '35px', color: '#5F6A6A'}}>Tecnologie</h2>
                <div style={{marginTop: '20px'}}></div>
                <TableSection type="VDSL" tag='R' name="_BITSTREAM_NGA_VULA_FTTCab" data={data}/>
                <TableSection type="EVDSL" tag='R' name="_EVDSL_BITSTREAM_NGA_VULA_FTTCab" data={data}/>
                <TableSection type="FTTH" tag='F' name="_BITSTREAM_NGA_VULA_FTTH" data={data}/>
                <TableSection type="FIBRA DEDICATA" tag='F' name="_CIRCUITI_TERM_ETH_FIBRA_OTTICA" data={data}/>
                <div style={{marginTop: '20px'}}></div>
            </div>
            <div style={{width: '95%', margin: 'auto',marginBottom: '5px'}}>I dati riportati sono stime non è assicurata tale velocità.</div>
        </div>
    )
}

export default GeneralData;