import React from "react";
import '../styles/otherPages.css'
import Navbar from "../Navbar";

import Warning from '../../img/warning.svg';

const Disclaimer = () => {
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: 'white', minHeight: 'calc(100vh - 302px)'}}>
                <img src={Warning} alt="" style={{ width: '180px', marginLeft: '10px'}}/>
                <h1 className="discTitle">Disclaimer</h1>
                <div style={{width: '98%'}}>
                    <br />
                    <p style={{fontSize: '28px', marginLeft: '20px'}}>I dati riportati su fiberfinder.it sono reperiti sui data base ufficiali degli operatori.</p>
                    <br />
                    <p style={{fontSize: '28px', marginLeft: '20px'}}>La verifica di copertura è da ritenersi sempre indicativa e subordinata ad una verifica tecnica da effettuarsi all’atto della ricezione dell’ordine di attivazione.</p>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer;