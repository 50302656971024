import { makeStyles } from "@material-ui/core/styles";
const TextFStyle = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(0.9);",
      },
      "&.Mui-focused .MuiInputLabel-outlined": {
        color: "#ff0f64"
      },
      "& .MuiInputLabel-outlined": {
        fontSize: "18px"
  
      },  
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "15px",
        borderWidth: "3px",
        fontSize: "17px",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "grey"
        }
      },
    },
    inputRoot: {
      color: "black",
      backgroundColor: "white",
      borderRadius: "15px",
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        borderRadius: "15px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ff0f64",
        borderRadius: "10px",
        borderWidth: "2px"
      },
      
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
        borderRadius: "10px",
        borderWidth: "1px"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "ff0f64",
        borderRadius: "10px",
        borderWidth: "2px",
        color: "red"
      },
      "&.Mui-checked .MuiOutlinedInput-notchedOutline": {
        borderColor: "ff0f64",
        borderRadius: "10px",
        borderWidth: "2px",
        color: "red"
      }
    }
  }));
export default TextFStyle;
