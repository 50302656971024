import React, { useState, useEffect } from 'react';
import './tlc_negativo.png';
import Logo from '../img/logoFiberFinder.svg';
import hamburger from '../img/menu.png';
import close from '../img/close.png';
import '../Layout Sito/style.css';
import {useNavigate} from 'react-router-dom';


const Navbar = () => {

    const [hg, setHg] = useState('100px')
    const [icon, setIcon] = useState(hamburger)
    const [ElementNumber, setElementNumber] = useState(0)
    const navigate = useNavigate();

    const navToggle = () => {
        setHg(!hg)
        setIcon(!icon)
    }
    useEffect(() => {
        window.addEventListener('resize', () => {
            if(window.innerWidth > 720) {
                setHg("100px")
            }
        })
        setElementNumber(document.getElementById('LinkList').childElementCount);
    },[])

    
    return(
        <div className="navbar">
            <div className="container">
                <img src={Logo} alt="" className="logo" unselectable='on' style={{userSelect: 'none'}} onClick={() => navigate('/', {replace: true})}/>
                {/* <div style={{width: '100px', height: '100px'}}></div> */}
                <div className="nav-toggle" id="navToggle" onClick={() => navToggle()}>
                    <img id="navClosed" className="navIcon" src={icon ? hamburger : close} alt="hamburger menu" />
                </div> 
                {/*Da migliorare*/}
                <div className="nav" style={{height: hg ? '0' : ElementNumber * 50 + "px"}}>
                    <ul id='LinkList'>
                        <li style={{cursor: 'pointer'}} onClick={() => navigate('/about-us', {replace: true})}><a href="/about-us" style={{pointerEvents: 'none'}}>Chi siamo</a></li>
                        <li style={{cursor: 'pointer'}} onClick={() => navigate('/contact-us', {replace: true})}><a href="/contact-us" style={{pointerEvents: 'none'}}>Contattaci</a></li>
                        <li style={{cursor: 'pointer'}} onClick={() => navigate('/FiberFinderApi', {replace: true})}><a href="/FiberFinderApi" style={{pointerEvents: 'none'}}>FiberFinderAPI</a></li>
                        <li style={{cursor: 'pointer'}} onClick={() => navigate('/private', {replace: true})}><a href="/private" style={{pointerEvents: 'none'}}>Area Privata</a></li>
                        <li style={{cursor: 'pointer'}} onClick={() => navigate('/iframe', {replace: true})}><a href="/iframe" style={{pointerEvents: 'none'}}>iframe</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
    
}

export default Navbar;