import React from "react";
import Navbar from "../Navbar";
import '../styles/otherPages.css'

import Phone from '../../img/phone.svg';
import Mail from '../../img/mail.svg';
import Home from '../../img/home.svg';
import Group from '../../img/group.svg';
import Operator from '../../img/operator2.svg';

const ContactUs = () => {
    return(
        <div style={{width: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: 'rgba(255, 15, 100, 0.5)', minHeight: 'calc(100vh - 302px)'}}>
                <div style={{backgroundColor: '#ECF0F1', height: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 className="discTitle">Contattaci</h1>            
                </div>           
                <div style={{width: '100%'}}>
                    <br />
                    <div className="contactSection">
                        <img src={Home} alt="" style={{width: '120px'}}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className="contactText">Per acquistare il servizio dopo aver effettuato una verifica di copertura:</p>
                            <br />
                            <div style={{display: 'flex', marginLeft: '20px'}}> 
                                    <img src={Phone} alt=""  style={{width: '32px', marginRight: '20px'}}/>
                                    <p className="contactInfo">800 13 28 24</p>
                            </div>
                            <div style={{display: 'flex', marginLeft: '20px', alignItems: 'center'}}>
                                <img src={Mail} alt="" style={{width: '34px', marginRight: '20px'}}/>
                                <a href="mailto:assistenza@tlctel.com" className="contactInfo">assistenza@tlctel.com</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="contactSection">
                        <img src={Group} alt="" style={{width: '120px'}}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className="contactText">Se sei una grande azienda e vuoi valutare la possibilità di portare internet presso la tua sede con una fibra ottica dedicata a progetto contatta:</p>
                            <br />
                            <div style={{display: 'flex', marginLeft: '20px'}}> 
                                    <img src={Phone} alt=""  style={{width: '32px', marginRight: '20px'}}/>
                                    <p className="contactInfo">0771 266657</p>
                            </div>
                            <div style={{display: 'flex', marginLeft: '20px', alignItems: 'center'}}>
                                <img src={Mail} alt="" style={{width: '34px', marginRight: '20px'}}/>
                                <a href="mailto:commerciale@tlctel.com" className="contactInfo">commerciale@tlctel.com</a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="contactSection">
                        <img src={Operator} alt="" style={{width: '120px'}}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className="contactText">Se sei un operatore e vuoi accedere alla parte privata del sito, per scaricare liste indirizzi coperti da un determinato servizio chiedi un preventivo a:</p>
                            <br />
                            <div style={{display: 'flex', marginLeft: '20px'}}> 
                                    <img src={Phone} alt=""  style={{width: '32px', marginRight: '20px'}}/>
                                    <p className="contactInfo">0771 321154</p>
                            </div>
                            <div style={{display: 'flex', marginLeft: '20px', alignItems: 'center'}}>
                                <img src={Mail} alt="" style={{width: '34px', marginRight: '20px'}}/>
                                <a href="mailto:commerciale@tlctel.com" className="contactInfo">commerciale@tlctel.com</a>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <div style={{backgroundColor: '#ECF0F1', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 className="discTitle" style={{fontSize: '2.6rem'}}>Hai bisogno di aiuto?</h1>            
                </div>  
                <br />
                <div className="contactSection">
                    <p className="contactText">Se riscontri problemi sul sito scrivi a:</p>
                    <div style={{display: 'flex', marginLeft: '20px', alignItems: 'center'}}>
                        <img src={Mail} alt="" style={{width: '34px', marginRight: '20px'}}/>
                        <a href="mailto:problemifiberfinder@tlctel.com" className="contactInfo otherText" >problemifiberfinder@tlctel.com</a>
                    </div>
                </div>  
                <br />
                <div className="contactSection">
                    <p className="contactText">Per qualsiasi altra necessità scrivi a:</p>
                    <div style={{display: 'flex', marginLeft: '20px', alignItems: 'center'}}>
                        <img src={Mail} alt="" style={{width: '34px', marginRight: '20px'}}/>
                        <a href="mailto:fiberfinder@tlctel.com" className="contactInfo otherText">fiberfinder@tlctel.com</a>
                    </div>
                </div>  
                <br />
            </div>
            <div style={{backgroundColor: 'white', height: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}></div>   
        </div>
    )
}

export default ContactUs;