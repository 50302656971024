import React from 'react';
import '../Layout Sito/style.css';
import { useNavigate } from 'react-router';
import { useDispatch} from 'react-redux'
import { changeAddress, changeCity, changeNumber} from '../actions';

const Button = (props) => {

  const dispatch = useDispatch();
  const setFilled = props.f;
  const filled = props.filled;
  let city = ""
  let address = ""
  let civic = ""
  
  const navigate = useNavigate();

  return(
    <button
    type='button'
    className="input submit"
    onClick={async () => { 
      city = document.getElementById("cname").value;
      dispatch(changeCity(city))
      address = document.getElementById("address").value;
      if (address === ""){
        dispatch(changeAddress({egon: "",name: ""}))
      }
      civic = document.getElementById("number").value;
      dispatch(changeNumber(civic))
      if(city.trim() === ""){
        alert("Devi inserire almeno il comune")
        return;
      }
      else if(civic.trim() === "" && address.trim() !== ""){
        alert("Puoi cercare un intero comune o un civico specifico")
        return;
      }
      if(filled)setFilled()
      navigate('/results', {replace: true})
    }}
    >
      Cerca
    </button>
  )

  }

export default Button;