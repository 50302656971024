import React from 'react'
import Navbar from '../Navbar';
import { CopyBlock, atomOneDark as theme} from 'react-code-blocks';

const IframeInfo = () => {
    return(
        <div style={{height: '100%', minHeight: 'calc(100vh - 202px)'}}>
            <Navbar />
            <div style={{backgroundColor: '#ECF0F1', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h1 className="discTitle aOTitle" style={{color: 'rgba(255, 15, 100, 0.7)'}}>INTEGRAZIONI</h1>            
            </div>
            <div style={{height: '5px'}}></div>
            <div style={{backgroundColor: '#ECF0F1', paddingBottom: '20px', width: '100%'}}>
                <div className="textContent">
                    <h1 className="discTitle" style={{color: 'rgba(255, 15, 100, 0.9', fontSize: '4rem', fontWeight: '700'}}>Aggiungici al tuo sito</h1>
                    <br />
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Da oggi potrai integrare le ricerche di <span style={{fontWeight: '700', color: '#ff0f64'}}>fiberfinder.it</span> sul tuo sito web utilizzando il nostro <span style={{fontWeight: '700', color: '#ff0f64'}}>iframe</span>.</p>
                    <br />
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Ti basterà aggiungere il codice HTML riportato di seguito per poter usufruire del nostro servizio.</p>
                    <br />
                    <CopyBlock
                        text={'<iframe \n\t style="border: initial; height: 450px; width: 500px;"\n\t sandbox="allow-scripts allow-same-origin"\n\t title="fiberfinderiframe"\n\t src="https://iframe.fiberfinder.it/">\n</iframe>'}
                        language={"html"}
                        showLineNumbers={true}
                        theme={theme}
                        codeBlock
                        customStyle={{width: '60%', margin: 'auto'}}
                    />
                    <br />
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}><span style={{fontWeight: '700', color: '#ff0f64'}}>Wordpress:</span> Per utilizzare l'iframe su WordPress, basterà inserire nella vostra pagina il componente 'HTML personalizzato' e successivamente incollare il codice.</p>
                    <br />  
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Ogni sito che utilizzerà il servizio avrà a disposizione 20 ricerche al giorno. Se si desidera rimuovere questo limite, è possibile scrivere una mail a <span style={{fontWeight: '700', color: '#ff0f64'}}>commerciale@tlctel.com</span> per concordare un piano personalizzato.</p>
                    <br />
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>Ecco come si presenta l'iframe, potete testarlo da qui:</p>
                    <br />
                    <div className='iframec'>
                        <iframe 
                            style={{border: 'initial', width: '100%', height: '450px'}}
                            sandbox="allow-scripts allow-same-origin"
                            title="fiberfinderiframe"
                            src="https://iframe.fiberfinder.it/">
                        </iframe>
                    </div>
                    <p className="contactText" style={{color: '#7B7D7D', fontWeight: '400'}}>L'iframe è in versione di prova, quindi potrebbero presentarsi errori. In tal caso, sarebbe molto utile se ce li segnalaste a <span style={{fontWeight: '700', color: '#ff0f64'}}>commerciale@tlctel.com</span>.</p>
                    <br />
                </div>
            </div>

        </div>
    )

}

export default IframeInfo;